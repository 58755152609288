import CardIcon from 'molecules/CardIcon';
import Logo from 'atoms/Logo';
import React from 'react';
import TimeLine from 'molecules/Timeline';
import Image from 'next/image';

const SidebarWithTimeline = ({
  className = '',
  height = 'h-screen',
  width,
  showTimeLine = false,
  timeLineListData = [
    {
      step: 1,
      active: false,
      desc: 'Business Details',
      visited: true,
    },
    {
      step: 2,
      active: true,
      desc: 'Select Doc Types',
      visited: false,
    },
    {
      step: 3,
      active: false,
      desc: 'Users and Roles',
      visited: false,
    },
    {
      step: 4,
      active: false,
      desc: 'Settings',
      visited: false,
    },
    {
      step: 5,
      active: false,
      desc: 'Select Plan',
      visited: false,
    },
  ],
  showImage = false,
  classForLogo = 'w-[13%] h-[9%]',
  type,
}) => {
  return (
    <div
      className={`hidden relative overflow-hidden w-full md:flex gap-y-2 flex-col border-r border-secondary-200 justify-between p-6 ${width} ${height} ${className}`}
    >
      <Logo
        src="/images/aftoLogoBlue.svg"
        alt="Afto"
        className={classForLogo}
      />
      {showTimeLine && <TimeLine timeLineListData={timeLineListData} />}
      <CardIcon className="mx-auto" type={type} />
      {showImage && (
        <div className="absolute left-1/2 inset-y-0 w-full h-full">
          <Image
            src="/images/sidebarImage.svg"
            alt="sidebar Image"
            layout="fill"
          />
        </div>
      )}
    </div>
  );
};

export default SidebarWithTimeline;
